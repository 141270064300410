import type { ComponentProps } from 'react';
import { Header } from './Header';
import { Heading } from './Heading';

type HeaderProps = ComponentProps<typeof Header>;

export const Hero = (props: HeaderProps) => {
  return (
    <Header {...props}>
      <Heading>Get your events on everyone&apos;s calendar.</Heading>
    </Header>
  );
};
