import { darken, lighten } from 'color2k';
import { type FormEventHandler, useEffect, useRef, useState } from 'react';
import { AiDisabledAlert } from '@/components/AiDisabledAlert';
import { Collapse } from '@/components/Collapse';
import { FadeInOut } from '@/components/FadeInOut';
import { FilePreview } from '@/components/FilePreview';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { UploadTarget } from '@/components/UploadTarget';
import { useQuickCreateContext } from '@/features/magic/contexts/QuickCreateContext';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { Button, Flex, Text } from '@/ui';
import { shakeElement } from '@/utils/shakeElement';
import { hasFilePrompt } from '../../utils/prompt';
import { Card } from '../Card';
import { LoadingText } from '../LoadingText';
import { SubmitButton } from './components/SubmitButton';

type Props = Omit<MotionFlexProps, 'onSubmit'> & {
  onSubmit: () => void;
};

export const PagePromptForm = ({ onSubmit, ...props }: Props) => {
  const {
    state,
    job,
    prompt,
    setPrompt,
    instructions,
    setInstructions,
    createMutation,
    resetAppState,
  } = useQuickCreateContext();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );
  const uploadRef = useRef<HTMLDivElement | null>(null);
  const { trackEvent } = useAnalytics();
  const isSmBreakpoint = useIsBreakpoint('sm');
  const isMdBreakpoint = useIsBreakpoint('md');
  const isLoading = state === 'loading';

  const [forceShowSubmitButton, setForceShowSubmitButton] = useState(false);
  useEffect(() => {
    if (!job) {
      setForceShowSubmitButton(false);
    }
  }, [job]);

  let showSubmitButton = true;
  if (forceShowSubmitButton) {
    showSubmitButton = true;
  } else if (isLoading || job) {
    showSubmitButton = false;
  } else if (!hasFilePrompt(prompt)) {
    showSubmitButton = false;
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    createMutation.reset();

    if (!prompt.file) {
      await shakeElement(uploadRef);
    } else {
      onSubmit();
    }
  };

  const handleFocus = () => {
    if (job) {
      setForceShowSubmitButton(true);
    }
  };

  const handleClearClick = () => {
    resetAppState();
    setInstructions('');
    trackEvent('quick-create:click again');
  };

  const handleFileSelect = (file: File | null) => {
    setPrompt({ file });

    // force the submit button to appear when selecting a new file while
    // viewing the results of a previous job
    if (job) {
      setForceShowSubmitButton(true);
    }
  };

  return (
    <MotionFlex
      as="form"
      direction="column"
      gap="2"
      // @ts-expect-error - onSubmit is a valid prop with `as`
      onSubmit={handleSubmit}
      {...props}
    >
      {disableAllAiFeatures && <AiDisabledAlert />}

      <Collapse in={createMutation.isError}>
        {createMutation.error && (
          <QuickCreateErrorMessage
            error={createMutation.error}
            type={job?.type}
          />
        )}
      </Collapse>

      <Flex direction="column" gap="2">
        <FadeInOut key={String(Boolean(state === 'edit' && prompt.file))}>
          {state === 'edit' && prompt.file ? (
            <Card direction="column" gap="4" justify="center" p="5">
              <FilePreview
                file={prompt.file}
                pdfWidth={isSmBreakpoint ? 200 : isMdBreakpoint ? 400 : 500}
              />

              {instructions && <Text fontWeight="medium">{instructions}</Text>}
            </Card>
          ) : (
            <UploadTarget
              bg="white"
              borderColor={prompt.file ? 'transparent' : '#c7b5e8'}
              borderRadius="xl"
              file={prompt.file}
              instructions={instructions}
              isDisabled={disableAllAiFeatures || isLoading}
              ref={uploadRef}
              w="100%"
              dragOverProps={{
                bg: lighten('#c7b5e8', 0.1),
                borderColor: darken('#c7b5e8', 0.2),
              }}
              onDropText={() => {}}
              onFocus={handleFocus}
              onInstructionsChange={setInstructions}
              onSelectFile={handleFileSelect}
            />
          )}
        </FadeInOut>

        <Collapse in={showSubmitButton}>
          <SubmitButton
            isDisabled={isLoading || disableAllAiFeatures}
            textAlign={isLoading ? 'left' : 'center'}
            onClick={() => trackEvent('quick-create:click generate')}
          >
            {isLoading ? (
              <LoadingText w="170px">🔮 Working our magic</LoadingText>
            ) : (
              <span>🪄 Create</span>
            )}
          </SubmitButton>
        </Collapse>

        {state === 'edit' && (
          <Button
            colorScheme="dark"
            fontSize="md"
            isDisabled={isLoading || disableAllAiFeatures}
            mt={{ base: 2, md: 1 }}
            textDecor="underline"
            variant="link"
            onClick={handleClearClick}
          >
            Clear and start over
          </Button>
        )}
      </Flex>
    </MotionFlex>
  );
};
