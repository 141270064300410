import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateExperience } from '@/features/experiences';
import { getEditLink } from '@/features/experiences/lib/experience';
import type { QueryError } from '@/utils/errors';
import { useQuickCreateContext } from '../contexts/QuickCreateContext';

type Ret = {
  createExperience: () => void;
  isPending: boolean;
  error: QueryError | null;
  reset: () => void;
};

export const useCreateExperienceFromMagicJob = (): Ret => {
  const navigate = useNavigate();
  const { job } = useQuickCreateContext();
  const { createExperienceFromMagicJob, ...rest } = useCreateExperience();

  const createExperience = useCallback(() => {
    if (job) {
      createExperienceFromMagicJob(
        job,
        {
          onSuccess: (result) => {
            navigate(getEditLink(result.createExperience));
          },
        },
        true
      );
    }
  }, [job, createExperienceFromMagicJob, navigate]);

  return {
    createExperience,
    ...rest,
  };
};
