import { useEffect } from 'react';
import { useQuickCreateContext } from '../contexts/QuickCreateContext';
import type { DecoratedMagicJob } from '../types';

type Arg = (job: DecoratedMagicJob) => void;

export const useJobComplete = (callback: Arg) => {
  const { state, job } = useQuickCreateContext();

  useEffect(() => {
    if (job && state === 'edit') {
      callback(job);
    }
    // Don't trigger effect on callback change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, state]);
};
