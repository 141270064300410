import { useMount } from 'react-use';
import { AppLoader } from '@/components/AppLoader';
import { AuthPanel } from '@/components/auth';
import { FadeInOut } from '@/components/FadeInOut';
import type { PageExperienceFragment } from '@/features/experiences/fragments/PageExperience.generated';
import { calcReadableColor } from '@/features/styles/hooks/useReadableColor';
import { getStyles } from '@/features/styles/lib/styles';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Footer } from '@/pages/QuickCreate/components/Footer';
import { Box, Flex, Heading, useToken } from '@/ui';
import { useCreateExperienceFromMagicJob } from '../hooks/useCreateExperienceFromMagicJob';
import type { DecoratedMagicJob } from '../types';

type Props = {
  experience: PageExperienceFragment;
  job: DecoratedMagicJob;
};

const getRedirectLink = (job: DecoratedMagicJob) => {
  const path = '/magic-page';

  const query: Record<string, string> = {};

  if (job.id) {
    query.e = job.id;
  }

  const queryParams = new URLSearchParams(query);
  const queryString = queryParams.toString();
  const search = queryString.length > 0 ? `?${queryString}` : '';

  return `${path}${search}`;
};

const visibleContentHeight = 300;
const gradientHeight = 100;
const authPaddingTop = 60;
const authTopOffset = visibleContentHeight + gradientHeight + authPaddingTop;

export const PreviewLeadWall = ({ experience, job }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { createExperience, isPending: isCreatingExperience } =
    useCreateExperienceFromMagicJob();
  const [colorOnLight, colorOnDark] = useToken('colors', ['dark.500', 'white']);
  const styles = getStyles(experience, isMobileBreakpoint);
  const color = calcReadableColor({ experience, colorOnLight, colorOnDark });

  useMount(() => {
    window.scrollTo({
      top: 0,
    });
  });

  return (
    <FadeInOut key={String(isCreatingExperience)}>
      {isCreatingExperience ? (
        <AppLoader />
      ) : (
        <Flex
          align="center"
          bgGradient={`linear(to-b, transparent ${visibleContentHeight}px, ${styles.visualBackgroundColor} ${visibleContentHeight + gradientHeight}px)`}
          direction="column"
          justify="flex-start"
          minH="100vh"
          pointerEvents="none"
          pos="absolute"
          pt={`${authTopOffset}px`}
          top="0"
          w="100vw"
          zIndex="50"
        >
          <Box pointerEvents="auto">
            <AuthPanel
              hideLogo
              redirectTo={getRedirectLink(job)}
              renderHeader={() => (
                <Heading
                  as="h2"
                  color={color}
                  fontSize={{ base: '2xl', sm: '4xl' }}
                  fontWeight="bold"
                  mb="7"
                  textAlign="center"
                >
                  Login or sign up to save your page
                </Heading>
              )}
              onAuthenticated={createExperience}
            />
            <Footer />
          </Box>
        </Flex>
      )}
    </FadeInOut>
  );
};
