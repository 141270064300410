import { useState } from 'react';
import { AppLoader } from '@/components/AppLoader';
import { FadeInOut } from '@/components/FadeInOut';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { useCurrentUserContext } from '@/contexts';
import { Bootstrap } from '@/features/magic/components/Bootstrap';
import { useMetaThemeColor } from '@/hooks/useMetaThemeColor';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Header } from '@/router/components/MagicTabsLayout/components/Header';
import { MagicJobType } from '@/types/gql.generated';
import { Box } from '@/ui';
import { isGQLErrorOfType, type QueryError } from '@/utils/errors';
import { AuthModal } from '../../components/AuthModal';
import { CenteredContent } from '../../components/CenteredContent';
import { Layout } from '../../components/Layout';
import { LoadingCard } from '../../components/LoadingCard';
import { MaintenanceMode } from '../../components/MaintenanceMode';
import { Hero } from '../../components/PageHero';
import { PreviewMagicExperience } from '../../components/PreviewMagicExperience';
import { PagePromptForm } from '../../components/PromptForm/PagePromptForm';
import { useQuickCreateContext } from '../../contexts/QuickCreateContext';
import { useBootstrappedJob } from '../../hooks/useBootstrappedJob';
import { useCreateExperienceFromMagicJob } from '../../hooks/useCreateExperienceFromMagicJob';
import { useJobComplete } from '../../hooks/useJobComplete';
import { NotFound } from '../NotFound';

const MagicPageContent = () => {
  const { isAuthenticated } = useCurrentUserContext();
  const { createEntries, job, prompt, state, resetAppState } =
    useQuickCreateContext();
  const { bootstrappedJobLoading, error: bootstrappedJobError } =
    useBootstrappedJob();
  const {
    createExperience,
    isPending: isCreatingExperience,
    error: createError,
    reset: resetCreate,
  } = useCreateExperienceFromMagicJob();
  const [isPreview, setIsPreview] = useState(false);
  const error: QueryError | null = createError || bootstrappedJobError;

  usePageTitle("Get your events on everyone's calendar");
  useMetaThemeColor('#e5eef5');

  useJobComplete(() => {
    if (isAuthenticated) {
      createExperience();
    } else {
      setIsPreview(true);
    }
  });

  const handleReset = () => {
    resetAppState();
    resetCreate();
    setIsPreview(false);
  };

  const handleCreateSubmit = () => {
    createEntries(prompt);
  };

  const contentState = isGQLErrorOfType(
    bootstrappedJobError,
    'QuickEntriesJobNotFound'
  )
    ? 'notfound'
    : error
      ? 'error'
      : state === 'loading'
        ? 'loading'
        : 'prompt';

  return (
    <FadeInOut key={String(isPreview)} w="100%">
      {bootstrappedJobLoading || isCreatingExperience ? (
        <AppLoader />
      ) : isPreview && job ? (
        <PreviewMagicExperience job={job} />
      ) : (
        <Layout layerStyle="magicPageBackground" minH="100vh">
          <Header />

          <Hero mb="8" />

          <FadeInOut key={contentState}>
            <CenteredContent
              gap="6"
              maxW="var(--chakra-breakpoints-magic-max-width)"
            >
              {isGQLErrorOfType(
                bootstrappedJobError,
                'QuickEntriesJobNotFound'
              ) ? (
                <NotFound />
              ) : error ? (
                <QuickCreateErrorMessage error={error} onReset={handleReset} />
              ) : state === 'loading' ? (
                <Box alignSelf="center" w={{ base: '100%', md: '50%' }}>
                  <LoadingCard />
                </Box>
              ) : (
                <PagePromptForm
                  alignSelf="center"
                  flex="1"
                  maxW="50%"
                  position="sticky"
                  top={4}
                  width="100%"
                  onSubmit={handleCreateSubmit}
                />
              )}
            </CenteredContent>
          </FadeInOut>
        </Layout>
      )}
    </FadeInOut>
  );
};

export const MagicPage = () => {
  return (
    <>
      <MaintenanceMode>
        <Bootstrap promptType={MagicJobType.File}>
          <AuthModal />
          <MagicPageContent />
        </Bootstrap>
      </MaintenanceMode>
    </>
  );
};
