import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import type { DecoratedMagicEvent } from '@/features/magic/types';
import { TimeZoneDisplay } from '@/types/gql.generated';
import { useCreateDecoratedExperienceEvents } from '../../../events/hooks/useCreateDecoratedExperienceEvents';
import type { PageExperienceFragment } from '../../fragments/PageExperience.generated';
import { PageExperienceContext } from './PageExperienceContext';
import type { PageExperienceContextValue } from './types';

type Props = {
  children: ReactNode;
  experience: PageExperienceFragment;
  events: DecoratedMagicEvent[];
};

export const MagicExperiencePreviewContextProvider = ({
  children,
  experience,
  events,
}: Props) => {
  const authorTimezone = experience.timeZone;

  const displayTimezone =
    experience.timeZoneDisplay === TimeZoneDisplay.Experience
      ? authorTimezone
      : DateTime.local().zoneName;

  const { decoratedExperienceEvents } = useCreateDecoratedExperienceEvents(
    experience,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    events as any,
    displayTimezone
  );

  const value: PageExperienceContextValue = {
    experience,
    authorTimezone,
    displayTimezone,
    events: decoratedExperienceEvents,
    readOnly: true,
    isPreview: true,
  };

  return (
    <PageExperienceContext.Provider value={value}>
      {children}
    </PageExperienceContext.Provider>
  );
};
