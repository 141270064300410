import { AppLoader } from '@/components/AppLoader';
import { FadeInOut } from '@/components/FadeInOut';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { MagicExperiencePreviewContextProvider } from '@/features/experiences/contexts/PageExperienceContext/MagicExperiencePreviewContextProvider';
import { useChakraTheme } from '@/features/experiences/hooks/useChakraTheme';
import { usePreviewMagicExperience } from '@/features/experiences/hooks/usePreviewMagicExperience';
import { ViewPageExperience } from '@/features/experiences/pages/ViewPageExperience/ViewPageExperience';
import { Box, ChakraProvider } from '@/ui';
import type { DecoratedMagicJob } from '../types';
import { PreviewLeadWall } from './PreviewLeadWall';

type Props = {
  job: DecoratedMagicJob;
};

export const PreviewMagicExperience = ({ job }: Props) => {
  const { data: experience, isPending, isError } = usePreviewMagicExperience();
  const { theme } = useChakraTheme();

  return (
    <FadeInOut key={`${isPending}${isError}`} w="100%">
      {isPending ? (
        <AppLoader />
      ) : isError ? (
        <QuickCreateErrorMessage />
      ) : (
        <Box pos="relative">
          <Box h="100vh" overflow="hidden" pos="absolute" top="0" w="100vw">
            <ChakraProvider theme={theme}>
              <MagicExperiencePreviewContextProvider
                events={job.events}
                experience={experience}
              >
                <ViewPageExperience />
              </MagicExperiencePreviewContextProvider>
            </ChakraProvider>
          </Box>
          <PreviewLeadWall experience={experience} job={job} />
        </Box>
      )}
    </FadeInOut>
  );
};
