import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useQuickCreateContext } from '@/features/magic/contexts/QuickCreateContext';
import { gqlClient } from '@/lib';
import type { PreviewMagicExperienceInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createExperienceQueryKey } from '@/utils/queryKeys';
import { PageExperienceFragment } from '../fragments/PageExperience';
import type { PageExperience } from '../types';
import type {
  PreviewMagicExperienceQuery,
  PreviewMagicExperienceQueryVariables,
} from './usePreviewMagicExperience.generated';

const query = gql`
  ${PageExperienceFragment}
  query PreviewMagicExperience($input: PreviewMagicExperienceInput!) {
    previewMagicExperience(input: $input) {
      ...PageExperience
    }
  }
`;

const selector = (data: PreviewMagicExperienceQuery): PageExperience => {
  return data.previewMagicExperience;
};

export const usePreviewMagicExperience = () => {
  const { job } = useQuickCreateContext();
  const queryKey = createExperienceQueryKey(String(job?.id));
  const magicJobId = job?.id;

  return useQuery<PreviewMagicExperienceQuery, QueryError, PageExperience>({
    enabled: Boolean(magicJobId),
    queryKey,
    select: selector,
    queryFn: () => {
      const input: PreviewMagicExperienceInput = {
        magicJobId: magicJobId!, // protected by the `enabled` setting
      };
      return gqlClient.request<
        PreviewMagicExperienceQuery,
        PreviewMagicExperienceQueryVariables
      >(query, { input });
    },
  });
};
